<template>
    <div class="article-list">
        <div>
            <a @click="getDetail"  href="javascript:;" class="cover ld" >
                <img  v-if="column.image.length" :src="column.image[0]" style="max-height:318px;width:100%;object-fit:cover;" ></img>
                <img  v-if="!column.image.length" :src="config.pc_article_default_image" style="max-height:318px;width:100%;object-fit:cover;" ></img>
            </a>
            <div  class="footer">
                <a  href="javascript:;" class="title">
                    <span class="line-2" >{{column.title}} </span>
                </a>
                <div  class="author-wrapper">
                    <a @click="goUserHome(column.user.id)"  href="javascript:;" class="author" >
                        <img class="author-avatar" :src="column.user.avatar">
                        <span  class="name">{{column.user.nickname}}</span>
                    </a>
                    <span v-if="!column.is_zan" @click="handleCollect(column.id,1)" class="like-wrapper">
                        <svg  t="1687229106466" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3113" width="16" height="16"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z m0 63.146667c-41.44 0-70.261333 15.189333-116.96 55.04-2.165333 1.845333-14.4 12.373333-17.941334 15.381333a32.32 32.32 0 0 1-41.770666 0c-3.541333-3.018667-15.776-13.536-17.941334-15.381333-46.698667-39.850667-75.52-55.04-116.96-55.04C230.186667 180.48 149.333333 281.258667 149.333333 426.698667 149.333333 537.6 262.858667 675.242667 493.632 834.826667a32.352 32.352 0 0 0 36.736 0C761.141333 675.253333 874.666667 537.6 874.666667 426.698667c0-145.44-80.853333-246.218667-206.88-246.218667z" fill="currentColor" p-id="3114"></path></svg>
                       <span class="count">{{column.zan_num}}</span>
                    </span>

                    <span v-if="column.is_zan" @click="handleCollect(column.id,0)" class="like-wrapper">
                        
                        <svg   t="1687838307393" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2714" width="16" height="16"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z" fill="#9cc259" p-id="2715"></path></svg>
                        <span class="count">{{column.zan_num}}</span>
                    </span>
                </div>
            </div>
        </div>
        
        <article-detail :detailInfo="column" :id="articleId" :articleDetailStatus.sync="articleDetailStatus" />
    </div>
</template>


<script>


import ArticleDetail from '@/components/article-detail/article-detail.vue';
import { apiHandleArticleCollect } from '@/api/app'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'article-list',
    components: {
        ArticleDetail
    },
    props: {
        column: {
            type: [String, Object],
            require: true
        }
    },
    computed: {
        ...mapGetters(['config','isLogin']),
    },
    methods: {
        
        // 取消 帖子 点餐 
        handleCollect(id,is_collect){
            var that = this
            if (!this.isLogin) return this.$router.push("/login");
            
            apiHandleArticleCollect({
                id:id,
                is_collect: is_collect == 1 ? is_collect : 0,
                type:2,
            }).then((res) => {
                that.column.is_zan = is_collect
                if (is_collect == 1){
                    that.column.zan_num = that.column.zan_num +1;
                } else {
                    that.column.zan_num = that.column.zan_num -1;
                }
                
            }).catch(err => {
            })
        },
        
        goUserHome(userId){
            this.$router.push({ 'path' :'/user', query:{'user_id' : userId} })
        },


        getDetail(){
            this.articleId = this.column.id
            this.articleDetailStatus = true
        }
    },

    data() {
        return {
            articleId:0,
            articleDetailStatus:false
        }
    },
}
</script>


<style lang="scss" scoped>

    .article-list {
        .cover{
                position: relative;
                width: 245px;
                display: flex;
                border-radius: 16px;
                overflow: hidden;
                box-shadow: 0 0 0 0.5px #33333321;
                margin-left: 0.5px;
                transition: 0.2s;
                transform: translateZ(0);
            }
            .cover:hover:after {
                background-color: rgba(0,0,0,.25);
            }
            .cover:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: background-color .2s;
                background-color: transparent;
                -webkit-transform: translateZ(0);
                border-radius: var(--148566c3);
            }
            .footer {
                padding: 12px;
                .title {
                    margin-bottom: 8px;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #333;
                }
                .author-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 20px;
                    color: #666;
                    font-size: 12px;
                    transition: color 1s;
                }
                .author-wrapper .author {
                    display: flex;
                    align-items: center;
                    color: inherit;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 12px;
                }
                .author-wrapper .author-avatar{
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    border: 0.5px solid #e6e6e6;
                }
                .like-wrapper {
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
                .count{
                    line-height:16px;
                }
            }
    }

</style>