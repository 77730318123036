<template>
    <div>
     <Transition >
        <div class="article-detail" v-if="dialogStatus"   >
        
            <div @click="cancel" class="back-btn"> 
                <el-button icon="el-icon-back" circle></el-button>
            </div>
            <div class="detail-modal"></div>
            <el-dialog
                :visible.sync="dialogStatus"
                width="60%"
                :custom-class="customClass"
                :modal="modal"
                @close="cancel"
                
                :destroy-on-close="destroyOnClose"
                :show-close="showClose"
                center>
                <div class="container">

                    <div class="dialog-left">
                        <div class="swiper">
                            <el-carousel v-if="detailInfo" trigger="click" height="666px">

                                <el-carousel-item v-if="detailInfo.image" v-for="(item,index) in detailInfo.image" :key="index">
                                    <el-image :preview-src-list="detailInfo.image" :fit="'fill'"   :src="item" class='detail-img' ></el-image>
                                </el-carousel-item>

                                <el-carousel-item v-if="!detailInfo.image" >
                                    <el-image  :fit="'fill'"  :src="config.pc_article_default_image" class='detail-img' ></el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>

                    <div  class="dialog-right">
                        
                        <div class="author-container" >
                            <div class="author">
                                <div  class="info" v-if="detailInfo.user" >
                                    <span  class="">
                                        <el-image  class="avatar" :src="detailInfo.user.avatar" style="width: 40px; height: 40px;border-radius:50%;"></el-image>
                                    </span>
                                    <span class="name" >{{detailInfo.user.nickname}}</span>
                                </div>
                                <div  class="follow">
                                    <el-button >关注</el-button>
                                </div>
                            </div>
                        </div>
                        <div  class="note-scroller">
                            <overlay-scrollbars
                                        class="scrollbars-contain"
                                        :options="{
                                            scrollbars: { autoHide: 'scroll' },
                                            overflowBehavior: { x: 'hidden' },
                                            callbacks: { onScroll: handleScroll },
                                        }"
                                >

                                <div  class="note-content">
                                    <div class="desc" v-html="detailInfo.content"></div>
                                    <div class="date">{{detailInfo.create_time}} {{detailInfo.region}}</div>
                                </div>
                                <div  class="comments-container">
                                    <div  class="total">共 {{detailInfo.comment_num}} 条评论</div>
                                    <div  tag="div" name="list" class="list-container">
                                        
                                            <div class="reply-lists" v-if="replyLists.length">
                                                <div
                                                    class="reply-item"
                                                    v-for="(item, index) in replyLists"
                                                    :key="index"
                                                >

                                                    <div class="avatar">
                                                        <el-image  v-if="item.user"  :src="item.user.avatar" style="width: 32px; height: 32px;border-radius:50%;"></el-image>
                                                    </div>
                                                    <div class="right">
                                                        <div class="author-wrapper">
                                                            {{item.user.nickname}}
                                                        </div>
                                                        <div v-html="item.content" class="reply-item__content muted"></div>
                                                        <div class="info">
                                                            <span>{{item.create_time}}</span>
                                                            <div class="interactions">
                                                                
                                                                <span @click="handleCommentCollect(item,1,index)"  v-if="!item.is_collect" class="like-wrapper">
                                                                    <svg  t="1687229106466" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3113" width="16" height="16"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z m0 63.146667c-41.44 0-70.261333 15.189333-116.96 55.04-2.165333 1.845333-14.4 12.373333-17.941334 15.381333a32.32 32.32 0 0 1-41.770666 0c-3.541333-3.018667-15.776-13.536-17.941334-15.381333-46.698667-39.850667-75.52-55.04-116.96-55.04C230.186667 180.48 149.333333 281.258667 149.333333 426.698667 149.333333 537.6 262.858667 675.242667 493.632 834.826667a32.352 32.352 0 0 0 36.736 0C761.141333 675.253333 874.666667 537.6 874.666667 426.698667c0-145.44-80.853333-246.218667-206.88-246.218667z" fill="currentColor" p-id="3114"></path></svg>
                                                                    <span class="count">{{item.collect_num}}</span>
                                                                </span>

                                                                <span  @click="handleCommentCollect(item,0,index)" v-if="item.is_collect" class="like-wrapper">
                                                                    <svg   t="1687838307393" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2714" width="16" height="16"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z" fill="#9cc259" p-id="2715"></path></svg>
                                                                    <span class="count">{{item.collect_num}}</span>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <el-empty v-else :image-size="100"></el-empty>
                                    
                                    </div>
                                </div>
                                

                            </overlay-scrollbars>
                        </div>
                        <!-- 内容 END -->

                        <div class="footer-reply">
                            <div  class="buttons">
                                <span v-if="detailInfo.is_zan" @click="handleCollect(0,2)"  class="like-wrapper">
                                    <svg   t="1687838307393" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2714" width="28" height="28"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z" fill="#9cc259" p-id="2715"></path></svg>
                                    <span class="count">{{detailInfo.zan_num}}</span>
                                </span>
                                <span v-if="!detailInfo.is_zan" @click="handleCollect(1,2)"  class="like-wrapper">
                                    <svg   t="1687838307393" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2714" width="28" height="28"><path d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z" fill="currentColor" p-id="2715"></path></svg>
                                    <span class="count">{{detailInfo.zan_num}}</span>
                                </span>

                                <span v-if="detailInfo.is_collect" @click="handleCollect(0 , 1)"  class="like-wrapper">
                                    <svg t="1687857067941" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2451" width="26" height="26"><path d="M946.44567 407.179676c-3.706415-11.056823-13.268188-19.154245-24.823362-20.929682l-263.117606-40.614L540.930913 93.91802c-5.108345-10.93198-16.071024-17.939581-28.155247-17.971303-0.030699 0-0.062422 0-0.062422 0-12.021801 0-22.985504 6.945179-28.155247 17.84646L365.79001 345.247138l-263.917832 39.507806c-11.399631 1.961678-20.774139 10.060123-24.355711 21.054525-3.613294 10.963703-0.778736 23.047926 7.257288 31.332612l191.202717 196.684568-45.6282 277.132808c-1.930979 11.741415 3.0208 23.608697 12.769838 30.491455 9.717316 6.882757 22.549575 7.630794 32.983205 1.868557l235.366565-129.47177 234.868215 130.282229c4.703115 2.616594 9.904581 3.924379 15.106046 3.924379 6.291287 0 12.551874-1.868557 17.877159-5.699816 9.748015-6.852058 14.763239-18.687618 12.862959-30.460756L747.488339 634.635061l191.669344-195.84341C947.286828 430.476265 950.121386 418.2365 946.44567 407.179676zM691.986257 602.337471c-6.882757 7.039323-10.060123 16.974603-8.471952 26.722618l37.000706 229.480508L526.666024 751.025908c-9.343809-5.232165-20.74344-5.232165-30.117948-0.062422L302.262138 857.856006l37.779442-229.387387c1.588171-9.717316-1.52575-19.652596-8.40953-26.754341L170.703847 436.176081l220.156142-31.686676c2.191922-0.378623 4.288676-0.980327 6.274914-1.771343 7.674796-2.63092 14.32117-8.233522 18.061354-16.156982l97.392144-206.183919 96.550986 206.712969c4.422729 9.406231 13.205766 16.008602 23.483854 17.596773l219.638349 33.886785L691.986257 602.337471z" fill="#9cc259" p-id="2452"></path></svg>
                                    <span class="count">{{detailInfo.collect_num}}</span>
                                </span>

                                <span v-if="!detailInfo.is_collect" @click="handleCollect(1 , 1)"  class="like-wrapper">
                                    <svg t="1687857067941" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2451" width="26" height="26"><path d="M946.44567 407.179676c-3.706415-11.056823-13.268188-19.154245-24.823362-20.929682l-263.117606-40.614L540.930913 93.91802c-5.108345-10.93198-16.071024-17.939581-28.155247-17.971303-0.030699 0-0.062422 0-0.062422 0-12.021801 0-22.985504 6.945179-28.155247 17.84646L365.79001 345.247138l-263.917832 39.507806c-11.399631 1.961678-20.774139 10.060123-24.355711 21.054525-3.613294 10.963703-0.778736 23.047926 7.257288 31.332612l191.202717 196.684568-45.6282 277.132808c-1.930979 11.741415 3.0208 23.608697 12.769838 30.491455 9.717316 6.882757 22.549575 7.630794 32.983205 1.868557l235.366565-129.47177 234.868215 130.282229c4.703115 2.616594 9.904581 3.924379 15.106046 3.924379 6.291287 0 12.551874-1.868557 17.877159-5.699816 9.748015-6.852058 14.763239-18.687618 12.862959-30.460756L747.488339 634.635061l191.669344-195.84341C947.286828 430.476265 950.121386 418.2365 946.44567 407.179676zM691.986257 602.337471c-6.882757 7.039323-10.060123 16.974603-8.471952 26.722618l37.000706 229.480508L526.666024 751.025908c-9.343809-5.232165-20.74344-5.232165-30.117948-0.062422L302.262138 857.856006l37.779442-229.387387c1.588171-9.717316-1.52575-19.652596-8.40953-26.754341L170.703847 436.176081l220.156142-31.686676c2.191922-0.378623 4.288676-0.980327 6.274914-1.771343 7.674796-2.63092 14.32117-8.233522 18.061354-16.156982l97.392144-206.183919 96.550986 206.712969c4.422729 9.406231 13.205766 16.008602 23.483854 17.596773l219.638349 33.886785L691.986257 602.337471z" fill="#333333" p-id="2452"></path></svg>
                                    <span class="count">{{detailInfo.collect_num}}</span>
                                </span>

                                <span  class="like-wrapper">
                                    <svg t="1687857147465" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3713" width="25" height="25"><path d="M523.946667 85.333333C802.773333 85.333333 982.826667 307.541333 982.826667 511.338667c0 242.837333-197.397333 448-458.837334 448-84.16 0-150.464-16.597333-221.610666-55.402667l-88.618667 50.197333c-27.797333 8.426667-50.474667 5.162667-68.010667-9.834666-17.557333-14.997333-25.130667-34.730667-22.72-59.2a19570.688 19570.688 0 0 0 29.653334-106.368C123.008 741.76 64 656.426667 64 511.338667 64 307.541333 245.141333 85.333333 523.946667 85.333333z m-1.28 64C304.064 149.333333 128 317.12 128 522.666667c0 77.354667 24.874667 151.125333 70.634667 213.184l5.397333 7.125333 18.218667 23.509333-36.970667 128.746667a0.32 0.32 0 0 0 0.490667 0.384l113.408-63.829333 26.752 14.592C385.237333 878.72 452.544 896 522.666667 896 741.269333 896 917.333333 728.213333 917.333333 522.666667S741.269333 149.333333 522.666667 149.333333z m-192 320a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667z m182.848 0a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667z m182.869333 0a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667z" fill="#333333" p-id="3714"></path></svg>
                                    <span class="count">{{detailInfo.comment_num}}</span>
                                </span>
                            </div> 
                            <div class="reply_input" >
                                <el-input   @keyup.enter.native="replyArticle" style="border-radius:40px;" placeholder="请输入内容" v-model="replyContent">
                                    <i slot="prefix" class="el-input__icon el-icon-edit"></i>
                                </el-input>
                            </div>
                        </div>
                        <!-- 评论区 END -->


                    </div>
                </div>
            
            </el-dialog>
            
        </div>
    </Transition>
</div>
</template>


<script>
import { apiArticleCommentsReply,apiArticleDetail,apiHandleArticleCollect,apiArticleCommentsLists } from '@/api/app'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'article-detail',

    props: {
        articleDetailStatus: {
            type: [Boolean],
            require: false
        },
        id:{
            type: [Number],
            require: false
        }
    },
    computed: {
        ...mapGetters(['config','isLogin']),
    },
    watch:{
        articleDetailStatus(){
            this.dialogStatus = this.articleDetailStatus

            if (this.dialogStatus ){
                this.getArticleInfo()
                this.getReplyListss()
            }
        }
    },
    data() {
        return {
            dialogStatus:false,
            destroyOnClose:true,
            showClose:false,
            modal:false,
            customClass:'detail-aialog',
            replyLists:[],
            page_no:1,
            page_size:10,
            is_page:true,
            replyContent:'',
            detailInfo:[],
            pid:0
        }
    },
    methods: {

        cancel(){

            this.detailInfo = []
            this.replyLists = []
            this.page_no   = 1
            this.page_size = 10
            this.is_page   = true
            this.replyContent   = ''

            this.$emit('update:articleDetailStatus',false)
        },
        handleScroll() {},

        // 获取帖子信息
        getArticleInfo(){
                            
            apiArticleDetail({
                id:this.id
            }).then((res) => {
                this.detailInfo = res
                
            }).catch(err => {
                
            })
            
        },

        replyArticle(){
            this.articleCommentsReply()
        },

        // 帖子回复
		articleCommentsReply(){
			var that = this;
			if (!this.isLogin) return this.$router.push("/login");
			
			apiArticleCommentsReply({
				article_id:that.id,
				pid:that.pid,
				data: {
                    'html':that.replyContent,
                    'text':that.replyContent
                }
			}).then((res) => {
                
                that.replyContent = ''

                this.getArticleInfo()
                this.page_no   = 1
                this.page_size = 10
                this.is_page   = true
                
                
				this.getReplyListss();
				
			}).catch(err => {
			})
		},

        // 获取回复列表
        getReplyListss(){

            const {id,page_no ,page_size} = this

            apiArticleCommentsLists({
                article_id:id,
                page_no: page_no,
				page_size: page_size
            }).then((res) => {

                this.replyLists = res.lists
                
            }).catch(err => {
            })
        },

        // 帖子点赞收藏
        handleCollect(is_collect , type){
            let that = this
            apiHandleArticleCollect({
                id:this.id,
                type:type,
                is_collect:is_collect
            }).then((res) => {
                this.getArticleInfo()

            }).catch(err => {
                
            })
        },

        // 帖子评论点赞收藏
        handleCommentCollect(row,is_collect,index,i ){
 
            let that = this
            apiHandleArticleCollect({
                id:this.id,
                comments_id:row.id,
                type:3,
                is_collect:is_collect
            }).then((res) => {
                
                if( i >= 0 ){
                    that.replyLists[index]['replyList'][i].is_collect = is_collect
                    
                    if (is_collect == 1){
                        that.replyLists[index]['replyList'][i].collect_num = that.replyLists[index]['replyList'][i].collect_num +1;
                    } else {
                        that.replyLists[index]['replyList'][i].collect_num = that.replyLists[index]['replyList'][i].collect_num -1;
                    }
                    
                } else {
                    that.replyLists[index].is_collect = is_collect
                    
                    if (is_collect == 1){
                        that.replyLists[index].collect_num = that.replyLists[index].collect_num +1;
                    } else {
                        that.replyLists[index].collect_num = that.replyLists[index].collect_num -1;
                    }
                    
                }
                
                
                
            }).catch(err => {
                
            })
        }
    }
}
</script>


<style lang="scss" scoped>
    
    @keyframes bounce-in {
        0% {
          width: 0;
        }
        100% {
          width: 200px;
        }
      }

    /deep/.el-dialog{
        background:#ffffff;
        .el-dialog__header{
            display:none;
            padding:0;
        }
        .el-dialog__body{
            padding:0;
        }
    }
    /deep/.el-carousel__arrow{
        background-color: #fff;
        color: #333;
    }
    /deep/.el-carousel__item{
        display: flex;
        place-content: center flex-start;
        align-items: center;
    }
    

    .article-detail{

        .detail-modal{
            background:#ffffff;
            position: fixed;
            display: flex;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index:1999;
        }
        
        

        .container{
            display:flex;
        }
        .dialog-left,.dialog-right{
            height:100%;
        }
        .dialog-left{
            width:55%;
            border-right: 0.5px solid rgba(0,0,0,.1);
            .swiper{
                width:100%;
                .detail-img{
                    border-top-left-radius:5px;
                    border-bottom-left-radius:5px;
                    width:100%;
                }
            }
        }
        .dialog-right{
            width:45%;
            .author-container{
                display: flex;
                .author {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 80px;
                    padding: 20px 24px;
                    border-bottom: 0.5px solid transparent;
                }
                .author .info {
                    display: flex;
                    align-items: center;
                }
                .author .name{
                    margin-left: 12px;
                    font-weight: 500;
                    font-size: 16px;
                    color: #333;
                }
            }

            .note-content{
                padding: 10px 0 20px;
                margin: 0 30px;
                color: #333;
                border-bottom: 0.5px solid rgba(0,0,0,0.1);
            }
            .comments-container {
                padding: 20px 30px;
            }

            .scrollbars-contain{
                max-height:480px;
            }
            .reply-item{
                position: relative;
                display: flex;
                margin-top: 16px;

                .right{
                    margin-left: 12px;
                    padding-bottom: 16px;
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    flex-grow: 1;
                    border-bottom: 0.5px solid rgba(0,0,0,.1);
                    .author-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .info {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 8px;
                        font-size: 12px;
                        line-height: 16px;
                        color: #999;
                        
                        .interactions{ display: flex;}
                        
                        
                    }

                }
            }

            .like-wrapper {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
            }
            .count{
                line-height:16px;
            }
        }

        .footer-reply{
            border-top: 0.5px solid rgba(0,0,0,.1);
            height: 102px;
            padding: 12px 18px 18px;
            position: absolute;
            bottom: 0;
            width: 45%;
            .buttons{
                display:flex;
                align-items: center;
                cursor: pointer;
                .like-wrapper{
                    margin-right:5px;
                    .count{
                        padding-left:5px;
                    }
                }
            }
            .reply_input{
                margin-top:10px;
            }
        }
 
        .back-btn {
            position: fixed;
            display: flex;
            z-index: 3001;
            transition: all .3s;
            cursor: pointer;
            left: 32px;
            top: 32px;
        }
    }

</style>